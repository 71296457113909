import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import ClearIndicator from '../../../../components/clear-interval-indicator/ClearIndicator';
import DropdownIndicator from '../../../../components/drop-down-indicator/DropdownIndicator';
import PageLoadingIndicator from '../../../../components/loading-indicator/page-loading';
import MenuSelectList from '../../../../components/menu-select-list/MenuSelectList';
import NoResults from '../../../../components/notifications/empty-data';
import PageHeaderWrapper from '../../../../components/page/page-header-wrapper';
import PageTitle from '../../../../components/page/page-title';
import PageWrapper from '../../../../components/page/page-wrapper';
import PageWrapperDataFilter from '../../../../components/page/page-wrapper-data-filter';
import selectContainer from '../../../../components/select-container';
import { defaultStyles } from '../../../../components/select-custom-styles';
import selectOptionsCheckbox from '../../../../components/select-options-checkbox';
import TableWrapper from '../../../../components/table/table-wrapper';
import withAPI from '../../../../hocs/with-api';
import { getFormattedCompArr, listToQuery } from '../../../../utils';
import RegularDropdown from 'components/regular-dropdown';

const columnNames = ['Name', 'Driver No', 'Company', 'Last login web', 'Last login app'] ;

class AppUsageReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      items: [],

      weeks: 4,

      isLoading: false,
      isAllCompanies: true,
    };
  }

  weekOptions = [
    { id: 1, name: '1 week' },
    { id: 2, name: '2 weeks' },
    { id: 3, name: '3 weeks' },
    { id: 4, name: '4 weeks' },
  ];

  componentDidMount = async () => {
    const { history } = this.props;
    history.push(`/reporting/drivers/app-usage`);
    this.loadCompanies();
    this.loadItems();
  }

  componentWillUnmount = () => {
    this.handleCompany.cancel();
  }

  loadCompanies = async () => {
    const { httpRequest, history } = this.props;
    const { location: { companyId } } = history;
    const response = await httpRequest({
      method: 'get',
      url: 'companies?is_suspended=0',
    });
    if (response) {
      const {
        data: {
          data: companies,
        },
      } = response;
      if (_.isArray(companies) && companies.length > 1) {
        const composedCompanies = getFormattedCompArr(companies);
        const specifiedCompany = composedCompanies.find((item) => item.value === companyId);
        this.setState({
          companies: composedCompanies,
          selectedCompanies: companyId ? [specifiedCompany] : composedCompanies,
          isAllCompanies: !companyId,
        });
        return null;
      }
      if (companies?.length === 1) {
        this.setState({
          selectedCompanies: [{ value: companies[0]?.id, label: companies[0]?.name }],
          companies,
        });
      }
    }
    return null;
  }

  loadItems = async () => {
    const { httpRequest } = this.props;
    const {weeks, selectedCompanies} = this.state;

    this.setState({ isLoading: true });

    this.cancelPrevRequestsOnLoadItems();

    this.cancelToken = axios.CancelToken.source();
    const queryCompany = listToQuery(selectedCompanies, '&companies[]=');

    const response = await httpRequest({
      method: 'get',
      url: 'reporting/drivers-not-using-app'
        + `?weeks=${weeks}`
        + `${queryCompany ? `&companies[]=${queryCompany}` : ''}`,
      cancelToken: this.cancelToken.token,
    }).catch((error) => {
      this.setState({
        isLoading: axios.isCancel(error),
      });
    });
    if (response) {
      const {
        data: {
          data: items,
        },
      } = response;
      this.setState({
        items,
        isLoading: false,
      });
    }
  }

  handleCompany = _.debounce(() => {
    this.setState({
      items: [],
    }, this.loadItems);
  }, 1000);

  onChangeSelect = (opts) => {
    const { companies, isAllCompanies } = this.state;
    const find = opts.filter((i) => i.value === '');
    if (isAllCompanies && _.isEmpty(find)) {
      this.setState({
        selectedCompanies: [],
        isAllCompanies: false,
      }, this.handleCompany);
    } else if (!_.isEmpty(find) && !isAllCompanies) {
      this.setState({
        selectedCompanies: companies,
        isAllCompanies: true,
      }, this.handleCompany);
    } else if (opts.length + 1 === companies.length && _.isEmpty(find) && !isAllCompanies) {
      this.setState({
        selectedCompanies: companies,
        isAllCompanies: true,
      }, this.handleCompany);
    } else if ((opts.length < companies.length) && !_.isEmpty(find)) {
      this.setState({
        selectedCompanies: opts.filter((i) => i.value !== ''),
        isAllCompanies: false,
      }, this.handleCompany);
    } else {
      this.setState({
        selectedCompanies: opts,
        isAllCompanies: false,
      }, this.handleCompany);
    }
  }

  cancelPrevRequestsOnLoadItems = () => {
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
  }

  render() {
    const {
      companies, selectedCompanies, isLoading, weeks, items,
    } = this.state;
    return (
      <PageWrapper>
        <PageHeaderWrapper>
          <PageTitle title="App Usage" />
        </PageHeaderWrapper>
        <PageWrapperDataFilter>
          <div className="md:w-max w-full gap-2 flex md:flex-row flex-col">
          <RegularDropdown
              items={this.weekOptions}
              onChange={(id) => this.setState({ weeks: id }, this.loadItems)}
              selectedValue={weeks}
              placeholder="Select week"
            />
          </div>
          {_.isArray(companies) && companies.length > 1 && (
            <div className="md:w-max w-full">
              <Select
                value={selectedCompanies}
                options={companies}
                onChange={this.onChangeSelect}
                isMulti
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                selectProps={{ isLimit: false }}
                components={{
                  Option: selectOptionsCheckbox,
                  ValueContainer: selectContainer,
                  MenuList: MenuSelectList,
                  DropdownIndicator,
                  ClearIndicator,
                }}
                maxMenuHeight={325}
                className="block w-full sm:text-sm rounded-md"
                name="totals_companies"
                placeholder="Select company..."
                classNamePrefix="select"
                styles={defaultStyles}
              />
            </div>
          )}
        </PageWrapperDataFilter>
        <TableWrapper>
          <PageLoadingIndicator loading={isLoading} />
          {!isLoading && items.length === 0 && selectedCompanies?.length > 0 && (
            <div className="absolute transform left-1/2 top-1/2 -translate-x-1/2 translate-y-1/2w-max">
              <NoResults />
            </div>
          )}
          {items?.length > 0 && (
            <>
              <table className="min-w-full divide-y divide-gray-200 overflow-y-auto">
                <thead className='sticky top-0 bg-white'>
                  <tr>
                    {
                      columnNames.map((columnName) => (
                        <th
                          key={columnName}
                          className="px-2 pb-4 tracking-wider font-medium whitespace-nowrap text-center"
                        >
                          <span className="flex items-center gap-2 px-4 font-medium text-sm">
                            {columnName}
                          </span>
                        </th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody
                  className={`${isLoading ? 'opacity-50' : ''} bg-white divide-y divide-gray-200 w-full`}
                >
                  {_.isArray(items) && items.map((item) => (
                    <tr className="whitespace-nowrap text-sm text-gray-900 w-full" key={item.id}>
                      <td className="pr-2 pl-6 py-2">{`${item.first_name} ${item.last_name}`}</td>
                      <td className="pr-2 pl-6 py-2">{item.driver?.driver_no ?? ''}</td>
                      <td className="pr-2 pl-6 py-2">{item.companies[0]?.name ?? ''}</td>
                      <td className="pr-2 pl-6 py-2">{item.last_login_web ?? 'N/A'}</td>
                      <td className="pr-2 pl-6 py-2">{item.last_login_app ?? 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </TableWrapper>
      </PageWrapper>
    );
  }
}

AppUsageReport.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default _.flowRight([
  withAPI,
  withRouter,
])(AppUsageReport);
