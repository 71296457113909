import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import ClearIndicator from '../../../../components/clear-interval-indicator/ClearIndicator';
import DropdownIndicator from '../../../../components/drop-down-indicator/DropdownIndicator';
import PageLoadingIndicator from '../../../../components/loading-indicator/page-loading';
import MenuSelectList from '../../../../components/menu-select-list/MenuSelectList';
import NoResults from '../../../../components/notifications/empty-data';
import PageHeaderWrapper from '../../../../components/page/page-header-wrapper';
import PageTitle from '../../../../components/page/page-title';
import PageWrapper from '../../../../components/page/page-wrapper';
import PageWrapperDataFilter from '../../../../components/page/page-wrapper-data-filter';
import selectContainer from '../../../../components/select-container';
import { defaultStyles } from '../../../../components/select-custom-styles';
import selectOptionsCheckbox from '../../../../components/select-options-checkbox';
import TableWrapper from '../../../../components/table/table-wrapper';
import withAPI from '../../../../hocs/with-api';
import { getFormattedCompArr, listToQuery } from '../../../../utils';
import RegularDropdown from 'components/regular-dropdown';
import { ExportButton } from 'components/buttons';
import { SearchIcon } from '@heroicons/react/outline';
import { setDate } from '../../../../components/dateHandle';

class HoursReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hubs: [],
      hubId: '',
      companies: [],
      items: [],
      dates: [],
      date: '',
      search: '',

      isLoading: false,
      isAllCompanies: true,
    };
  }

  componentDidMount = async () => {
    const { history } = this.props;
    history.push(`/reporting/drivers/hours`);
    this.loadCompanies();
    this.loadHubs();
    this.loadDates();
  }

  componentWillUnmount = () => {
    this.handleCompany.cancel();
  }

  loadDates = async () => {
    const { httpRequest } = this.props;
    const response = await httpRequest({
      method: 'get',
      url: 'payrolls/dates',
    }).catch((error) => {
      this.setState({
        errors: error?.response?.data || {},
        isLoading: false,
      });
    });
    if (response) {
      const {
        data: {
          data: dates,
        },
      } = response;
      const formattedDates = _.map(dates, (date) => {
        const obj = {};
        obj.id = date.key;
        obj.name = date.name;
        return obj;
      });
      this.setState({ dates: formattedDates });
    }
  }

  loadCompanies = async () => {
    const { httpRequest, history } = this.props;
    const { location: { companyId } } = history;
    const response = await httpRequest({
      method: 'get',
      url: 'companies?is_suspended=0',
    });
    if (response) {
      const {
        data: {
          data: companies,
        },
      } = response;
      if (_.isArray(companies) && companies.length > 1) {
        const composedCompanies = getFormattedCompArr(companies);
        const specifiedCompany = composedCompanies.find((item) => item.value === companyId);
        this.setState({
          companies: composedCompanies,
          selectedCompanies: companyId ? [specifiedCompany] : composedCompanies,
          isAllCompanies: !companyId,
        });
        return null;
      }
      if (companies?.length === 1) {
        this.setState({
          selectedCompanies: [{ value: companies[0]?.id, label: companies[0]?.name }],
          companies,
        });
      }
    }
    return null;
  }

  loadHubs = async () => {
    const { httpRequest } = this.props;
    const response = await httpRequest({
      method: 'get',
      url: 'hubs?in_my_companies=1',
    });
    if (response) {
      const {
        data: {
          data: hubs,
        },
      } = response;
      this.setState({ hubs: [{ id: '', name: 'All locations' }, ...hubs]});
    }
  }

  loadItems = async () => {
    const { httpRequest } = this.props;
    const {date, search, selectedCompanies, hubId} = this.state;

    if (! date) {
      return;
    }

    this.setState({ isLoading: true });

    this.cancelPrevRequestsOnLoadItems();

    this.cancelToken = axios.CancelToken.source();
    const queryCompany = listToQuery(selectedCompanies, '&companies[]=');

    const response = await httpRequest({
      method: 'get',
      url: 'reporting/drivers-hours'
        + `?date=${date}`
        + `&search=${search}`
        + `&hub_id=${hubId}`
        + `${queryCompany ? `&companies[]=${queryCompany}` : ''}`,
      cancelToken: this.cancelToken.token,
    }).catch((error) => {
      this.setState({
        isLoading: axios.isCancel(error),
      });
    });
    if (response) {
      const {
        data: {
          data: items,
        },
      } = response;
      this.setState({
        items,
        isLoading: false,
      });
    }
  }

  handleCompany = _.debounce(() => {
    this.setState({
      items: [],
    }, this.loadItems);
  }, 1000);

  onChangeSelect = (opts) => {
    const { companies, isAllCompanies } = this.state;
    const find = opts.filter((i) => i.value === '');
    if (isAllCompanies && _.isEmpty(find)) {
      this.setState({
        selectedCompanies: [],
        isAllCompanies: false,
      }, this.handleCompany);
    } else if (!_.isEmpty(find) && !isAllCompanies) {
      this.setState({
        selectedCompanies: companies,
        isAllCompanies: true,
      }, this.handleCompany);
    } else if (opts.length + 1 === companies.length && _.isEmpty(find) && !isAllCompanies) {
      this.setState({
        selectedCompanies: companies,
        isAllCompanies: true,
      }, this.handleCompany);
    } else if ((opts.length < companies.length) && !_.isEmpty(find)) {
      this.setState({
        selectedCompanies: opts.filter((i) => i.value !== ''),
        isAllCompanies: false,
      }, this.handleCompany);
    } else {
      this.setState({
        selectedCompanies: opts,
        isAllCompanies: false,
      }, this.handleCompany);
    }
  }

  handleSearch = _.debounce((query) => {
    this.setState({ search: query }, () => {
      this.loadItems();
    });
  }, 500);

  cancelPrevRequestsOnLoadItems = () => {
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
  }

  getLogDate = (date, logs) => {
    let log = logs.find(log => log.date === date.raw);
    return log ? log.hours : null;
  }

  onExport = async () => {
    const { httpRequest } = this.props;
    const { date, search, selectedCompanies, hubId } = this.state;
    this.setState({ isLoading: true });

    this.cancelToken = axios.CancelToken.source();
    const queryCompany = listToQuery(selectedCompanies, '&companies[]=');

    const response = await httpRequest({
      method: 'get',
      url: 'reporting/drivers-hours'
        + `?date=${date}`
        + `&export=1`
        + `&search=${search}`
        + `&hub_id=${hubId}`
        + `${queryCompany ? `&companies[]=${queryCompany}` : ''}`,
      cancelToken: this.cancelToken.token,
    });
    const data = new Blob([response?.data], { contentType: 'text/csv' });
    const fileName = `reporting-drivers-hours-${setDate(new Date())}.csv`;
    if (window.navigator.msSaveBlob) { // IE
      window.navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.setAttribute('download', fileName);
      link.setAttribute('href', url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    this.setState({ isLoading: false });
  }

  render() {
    const {
      companies, selectedCompanies, isLoading, items, dates, date, hubs, hubId,
    } = this.state;
    return (
      <PageWrapper>
        <PageHeaderWrapper>
          <PageTitle title="Driver Hours" />
          {date && (
            <ExportButton onClick={this.onExport} isLoading={isLoading} />
          )}
        </PageHeaderWrapper>
        <PageWrapperDataFilter>
          <div className="relative w-full sm:mr-auto sm:w-max">
            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              onChange={(e) => this.handleSearch(e.target.value)}
              id="search"
              name="search"
              className="md:block flex flex-1 ls:w-max xl:w-max w-full
                        bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3
                        text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900
                        focus:placeholder-gray-400 sm:text-sm"
              placeholder="Search"
              type="search"
            />
          </div>
          <div className="md:w-max w-full gap-2 flex md:flex-row flex-col">
            <RegularDropdown
              items={dates}
              onChange={(id) => this.setState({ date: id }, () => this.loadItems())}
              selectedValue={date}
              placeholder="Select date"
            />
          </div>
          <div className="md:w-max w-full gap-2 flex md:flex-row flex-col">
            <RegularDropdown
              items={hubs}
              onChange={(hubId) => this.setState({ hubId }, this.loadItems)}
              selectedValue={hubId}
              placeholder="Select location"
            />
          </div>
          {_.isArray(companies) && companies.length > 1 && (
            <div className="md:w-max w-full">
              <Select
                value={selectedCompanies}
                options={companies}
                onChange={this.onChangeSelect}
                isMulti
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                selectProps={{ isLimit: false }}
                components={{
                  Option: selectOptionsCheckbox,
                  ValueContainer: selectContainer,
                  MenuList: MenuSelectList,
                  DropdownIndicator,
                  ClearIndicator,
                }}
                maxMenuHeight={325}
                className="block w-full sm:text-sm rounded-md"
                name="totals_companies"
                placeholder="Select company..."
                classNamePrefix="select"
                styles={defaultStyles}
              />
            </div>
          )}
        </PageWrapperDataFilter>
        <TableWrapper>
          <PageLoadingIndicator loading={isLoading} />

          {!isLoading && !_.isArray(items.items) && selectedCompanies?.length > 0 && date !== '' && (
            <div className="absolute transform left-1/2 top-1/2 -translate-x-1/2 translate-y-1/2w-max">
              <NoResults />
            </div>
          )}

          {_.isArray(items.items) && items?.items.length > 0 && (
            <>
              <table className="min-w-full divide-y divide-gray-200 overflow-y-auto">
                <thead className='sticky top-0 bg-white'>
                  <tr>
                    <th key={1} className="px-2 pb-4 tracking-wider font-medium whitespace-nowrap text-center">
                      <span className="flex items-center gap-2 px-4 font-medium text-sm">Driver</span>
                    </th>
                    <th key={2} className="px-2 pb-4 tracking-wider font-medium whitespace-nowrap text-center">
                      <span className="flex items-center gap-2 px-4 font-medium text-sm">Total</span>
                    </th>
                    {_.isArray(items.dates) && items.dates.map((date) => (
                      <th key={date.raw} className="px-2 pb-4 tracking-wider font-medium whitespace-nowrap text-center">
                        <span className="flex items-center gap-2 px-4 font-medium text-sm">{date.formatted}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody
                  className={`${isLoading ? 'opacity-50' : ''} bg-white divide-y divide-gray-200 w-full`}
                >
                  {_.isArray(items.items) && items.items.map((item) => (
                    <React.Fragment key={`${item.group}-group`}>
                      <tr className="whitespace-nowrap text-sm text-gray-900 w-full bg-gray-200" key={item.group}>
                        <td className="pr-2 pl-6 py-2" colSpan={9}>{item.group}</td>
                      </tr>
                      {item.items.map((hubData) => (
                        <React.Fragment key={`${item.group}-${hubData.hub.id}-group`}>
                          <tr className="whitespace-nowrap text-sm text-gray-900 w-full bg-gray-100" key={item.group + hubData.hub.id}>
                            <td className="pr-2 pl-6 py-2" colSpan={9}>{hubData.hub.name}</td>
                          </tr>
                          {hubData.hub_logs.map((hubLogs) => (
                            <tr className="whitespace-nowrap text-sm text-gray-900 w-full" key={item.group + hubData.hub.id + hubLogs.driver.id}>
                              <td className="pr-2 pl-6 py-2">{`${hubLogs.driver.user.first_name} ${hubLogs.driver.user.last_name} - ${hubLogs.driver.driver_no}`}</td>
                              <td className="pr-2 pl-6 py-2 bg-gray-100">{hubLogs.total.hours}</td>
                              {items.dates.map((date, index) => (
                                this.getLogDate(date, hubLogs.logs) ? <td className="pr-2 pl-6 py-2" key={`${index}-log`}>{this.getLogDate(date, hubLogs.logs)}</td> : <td className="pr-2 pl-6 py-2" key={`${index}-log`}>-</td>
                              ))}
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </TableWrapper>
      </PageWrapper>
    );
  }
}

HoursReport.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default _.flowRight([
  withAPI,
  withRouter,
])(HoursReport);
