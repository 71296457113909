import { PageLoadingIndicator } from 'components/loading-indicator';
import DVIRViewDetails from 'components/modal/dvir-view-details';
import { PageHeaderBtn, PageHeaderWrapper, PageTitle, PageWrapper, PageWrapperBtn } from 'components/page';
import Pagination from 'components/pagination';
import { TableWrapper, Thead } from 'components/table';
import { withAPI, withUserInfo } from 'hocs';
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { useLoadTruckChecks } from '../hooks';
import DVIRRow from './dvir-row';
import PropTypes from 'prop-types';
import PageWrapperDataFilter from 'components/page/page-wrapper-data-filter';
import useLoadDVIRTypes from '../hooks/useLoadDVIRTypes';
import useLoadCompanies from 'hooks/api/useLoadCompanies';
import { MultipleSelector } from 'components/multiple-selector';
import { getFormattedCompArr, hasPermission } from 'utils';
import { useDidMountEffect, useDownloadFile } from 'hooks';
import PageSearch from 'components/page/page-search';
import RegularDropdown from 'components/regular-dropdown';
import { NoResults } from 'components/notifications';
import WithPermission from 'modules/with-permission';
import { CreateReview, DVIRCreate } from './modals';
import { useParams } from 'react-router-dom';
import { teamTypes } from 'components/modal/add-safety-alert';
import { useLoadOwnLocations } from 'hooks/api';
import moment from 'moment';
import { getFormattedDateAPI } from 'components/dateHandle';
import ReactDatePicker from 'react-datepicker';
import { ExportButton } from 'components/buttons';

const columnList = ['Truck No', 'Driver', 'Date', 'Type', 'Team type', 'Location', 'Status', 'Reviewed by', 'Details']

export const DVIRActions = {
  preTrip: "Pre Trip",
  postTrip: "Post Trip",
  truckIssues: "Truck Issue",
}

const statusMeta = [
  {id: '', name: 'All Statuses'},
  {id: 'pass', name: 'Pass'},
  {id: 'fail', name: 'Fail'},
  {id: 'fail_1', name: 'Fail (New)'},
  {id: 'fail_2', name: 'Fail (Resolved)'},
]

const DVIR = ({ httpRequest, permissions }) => {

  const { truck_no, truck_check_id } = useParams();
  const [currentPage, setCurrentPage] = useState(1)
  const { data: companies } = useLoadCompanies(httpRequest);
  const [selectedType, setSelectedType] = useState('');

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [search, setSearch] = useState(truck_no || '');
  const [isOpenedWithParams, setIsOpenedWithParams] = useState(false);

  const [selectedTeamType, setSelectedTeamType] = useState(0);

  const [from, setFrom] = useState(moment().subtract(1, 'month').add(1, 'day').toDate());
  const [to, setTo] = useState(moment().toDate());

  const { data: types } = useLoadDVIRTypes(httpRequest);
  const { downloadFile, isLoading: isDownloading } = useDownloadFile(httpRequest);
  const { locations, loadLocations } = useLoadOwnLocations(httpRequest, { teamType: selectedTeamType, companies: selectedCompanies });
  const [selectedLocations, setSelectedLocations] = useState([]) ;

  const openByTruckCheckId = useCallback((data) => {
    if (truck_check_id && data?.length > 0 && !isOpenedWithParams) {
      const currentData = data?.find((item) => item?.id === +truck_check_id);
      if (currentData) {
        setDVIRModalOpen(true);
        setDVIRDataDetails(currentData);
        setIsOpenedWithParams(true);
      }
    }
  }, [truck_check_id, isOpenedWithParams]);

  const {
    data,
    isLoading,
    pagination,
    loadTruckChecks,
  } = useLoadTruckChecks(
    httpRequest,
    {
      page: currentPage,
      type: selectedType,
      status: selectedStatus,
      search,
      companies: selectedCompanies,
      callback: openByTruckCheckId,
      teamType: selectedTeamType,
      locations: selectedLocations,
      from: getFormattedDateAPI(from),
      to: getFormattedDateAPI(to),
    }
  );

  const [DVIRModalOpen, setDVIRModalOpen] = useState(false);
  const [DVIRDataDetails, setDVIRDataDetails] = useState({});
    
  const [isDVIRCreateModalOpen, setIsDVIRModalOpen] = useState(false);
  const [DVIRModalAction, setDVIRModalAction] = useState('');

  const [isDVIRCreatePreviewModalOpen, setIsDVIRCreateModalOpen] = useState(false);

  const debouncedCompanySelect = _.debounce((company) => {
    setSelectedCompanies(company);
    setCurrentPage(1);
  }, 500)

  const debouncedLocationsSelect = _.debounce((locations) => {
    setSelectedLocations(locations);
    setCurrentPage(1);
  }, 500)

  const debouncedSearch = _.debounce((search) => {
    if (search.length !== 1) {
      setCurrentPage(1);
      setSearch(search);
    }
  }, 500)

  useDidMountEffect(() => {
    setSelectedCompanies(getFormattedCompArr(companies));
  }, [companies])

  useEffect(() => {
    return () => {
      debouncedCompanySelect.cancel();
      debouncedSearch.cancel();
    }
  }, [debouncedCompanySelect, debouncedSearch])

  useEffect(() => document.title = `DVIR List - ${process.env.REACT_APP_NAME}`)

  const onOpenDVIRCreateModal = (action) => {
    setDVIRModalAction(action);
    setIsDVIRModalOpen(true);
  }

  const menuItems = [
    {
      permissionCheck: ['CompaniesResources', 'DVIR'],
      label: 'Download',
      callback: async ({ id }) => {
        await downloadFile({
          url: `/truck-checks/${id}/pdf`,
          fileName: `Truck-Checks`,
          contentType: 'application/pdf',
          extension: '.pdf',
          responseType: 'arraybuffer',
          openInNewTab: true,
        })
      },
    },
    {
      permissionCheck: ['CompaniesResources', 'DVIR'],
      label: 'Create Review',
      condition: ({ dvir_items }) => {
        return dvir_items?.filter((item) => !item?.pass)?.length !== 0;
      },
      callback: async (dvir) => {
        setIsDVIRCreateModalOpen(true);
        setDVIRDataDetails(dvir);
      },
    },
  ]

  const onExport = () => {
    loadTruckChecks(true);
  }

  useDidMountEffect(() => {
    if (hasPermission(['CompaniesResources', 'DVIR'], permissions)) {
      loadLocations()
        .then(setSelectedLocations);
    }
  }, [loadLocations])

  useDidMountEffect(() => {
    loadTruckChecks();
  }, [loadTruckChecks])

  return (
    <PageWrapper>
      <DVIRViewDetails
        open={DVIRModalOpen}
        setOpen={(open) => { setDVIRModalOpen(open); setDVIRDataDetails({}); }}
        dvir={DVIRDataDetails}
      />
      {isDVIRCreateModalOpen && (
        <DVIRCreate
          open={isDVIRCreateModalOpen}
          setOpen={() => setIsDVIRModalOpen(false)}
          action={DVIRModalAction}
          meta={types}
          refreshData={loadTruckChecks}
        />
      )}
      {isDVIRCreatePreviewModalOpen && (
        <CreateReview
          httpRequest={httpRequest}
          open={isDVIRCreatePreviewModalOpen}
          setOpen={(open) => { setIsDVIRCreateModalOpen(open); setDVIRDataDetails({}); }}
          dvirId={DVIRDataDetails?.id}
          refreshItems={loadTruckChecks}
        />
      )}
      <PageHeaderWrapper>
        <PageTitle title="DVIR List" withFAQsPage />
        <WithPermission permissionsCheck={["CompaniesResources"]}>
          <PageWrapperBtn>
            <ExportButton onClick={onExport} label="Export" isLoading={isLoading} />
          </PageWrapperBtn>
        </WithPermission>
        <WithPermission permissionsCheck={["CanDrive"]}>
          <PageWrapperBtn>
            <PageHeaderBtn label="Truck-issues" onClick={() => onOpenDVIRCreateModal(DVIRActions.truckIssues)} />
            <PageHeaderBtn label="Post-trip" onClick={() => onOpenDVIRCreateModal(DVIRActions.postTrip)} />
            <PageHeaderBtn label="Pre-trip" onClick={() => onOpenDVIRCreateModal(DVIRActions.preTrip)} />
          </PageWrapperBtn>
        </WithPermission>
      </PageHeaderWrapper>

      <PageWrapperDataFilter>
        <PageSearch search={search} onChange={debouncedSearch} />
        <div className="md:w-max w-full">
          <ReactDatePicker
            selected={from}
            onChange={(date) => {setCurrentPage(1); setFrom(date);}}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                    block w-full sm:text-sm border-gray-300 rounded-md"
            placeholderText="from"
          />
        </div>
        <div className="md:w-max w-full">
          <ReactDatePicker
              selected={to}
              onChange={(date) => {setCurrentPage(1); setTo(date);}}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                      block w-full sm:text-sm border-gray-300 rounded-md"
              placeholderText="to"
            />
        </div>
        {locations?.length > 0 && (
          <div className="md:w-max w-full">
            <MultipleSelector
              placeholder="Select locations..."
              options={locations}
              defaultSelectedOptions={locations}
              onChangeSelectedOptions={debouncedLocationsSelect}
            />
          </div>
        )}
        <RegularDropdown
          items={types}
          onChange={(type) => {setSelectedType(type); setCurrentPage(1);} }
          selectedValue={selectedType}
          placeholder="Select type"
          customMargin="ml-0"
        />
        <RegularDropdown
          items={teamTypes.map((i) => ({ id: i.value , name: i.label  }))}
          onChange={(type) => { setSelectedTeamType(type); setCurrentPage(1); }}
          selectedValue={selectedTeamType}
          placeholder="Select team type"
          customMargin="ml-0"
        />
        <RegularDropdown
          items={statusMeta}
          onChange={(status) => {setSelectedStatus(status); setCurrentPage(1)}}
          selectedValue={selectedStatus}
          placeholder="Select status"
          customMargin="ml-0"
        />
        {_.isArray(companies) && companies.length > 1 && (
          <div className="md:w-max w-full">
            <MultipleSelector
              options={getFormattedCompArr(companies)}
              defaultSelectedOptions={getFormattedCompArr(companies)}
              onChangeSelectedOptions={debouncedCompanySelect}
            />
          </div>
        )}
      </PageWrapperDataFilter>
      <TableWrapper>
        <PageLoadingIndicator loading={isLoading || isDownloading} />
        <NoResults centerContent isVisible={data?.length === 0 && !isLoading} />
        <table className="divide-y divide-gray-200 overflow-x-hidden overflow-y-visible w-full">
          {data?.length > 0 && (
            <Thead
              columnList={columnList}
            />
          )}
          <tbody className={`w-full divide-y ${isLoading ? 'opacity-50' : ''}`}>
            {data?.length > 0 && data.map((dvir) => (
              <DVIRRow
                key={dvir?.id}
                data={dvir}
                onClickView={(item) => {
                  setDVIRDataDetails(item)
                  setDVIRModalOpen(true);
                }}
                menuItems={menuItems}
                httpRequest={httpRequest}
                refreshItems={loadTruckChecks}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          {...pagination}
          currentPage={currentPage}
          onChange={(page) => setCurrentPage(page)}
        />
      </TableWrapper>
    </PageWrapper>
  )
}

DVIR.propTypes = {
  httpRequest: PropTypes.func.isRequired,
}

export default _.flowRight([withAPI, withUserInfo])(DVIR);