import { CheckIcon } from '@heroicons/react/outline';
import React from 'react';
import PropTypes from 'prop-types';
import { isNotMobile } from '../../utils';

const FormRadioCheckBox = ({
  label, checked, onCheck, dataTestId, onMouseEnter, onMouseLeave,
  isHint, hintText, isDisabled, className,
}) => (
  <label
    className="font-medium text-gray-regular no-arrow w-full"
    onMouseEnter={() => onMouseEnter()}
    onMouseLeave={() => onMouseLeave()}
  >
    <div
      className={`w-full flex gap-3 items-start justify-start
        text-${checked ? 'green' : 'gray'}-600 h-5 w-5
        rounded-full cursor-pointer ${className}`}
      data-testid={dataTestId}
    >
      <div className={`flex items-center justify-center h-5 w-5
        rounded-full bg-${checked ? 'green' : 'gray'}-100`}
      >
        <CheckIcon
          className="h-3 w-3"
          aria-hidden="true"
        />
      </div>
      <span className="text-sm whitespace-nowrap">{label}</span>
    </div>

    <input
      type="checkbox"
      checked={checked}
      onChange={() => onCheck()}
      disabled={isDisabled}
      className="focus:ring-indigo-500 h-4 w-4
        text-indigo-600 border-gray-300 rounded hidden"
    />
    {isNotMobile() && isHint && (
      <div className="relative whitespace-pre-wrap w-full">
        <div
          className="text-xs absolute z-10 left-1/4 top-0 z-2 py-2 px-4 bg-gray-100
          border border-black-100 rounded-2xl text-left block
          font-medium transform -translate-x-1/2 break-words w-full"
        >
          {hintText}
        </div>
      </div>
    )}
  </label>
);

FormRadioCheckBox.propTypes = {
  onCheck: PropTypes.func.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  hintText: PropTypes.string,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  isHint: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

FormRadioCheckBox.defaultProps = {
  checked: false,
  isDisabled: false,
  isHint: false,
  hintText: '',
  label: '',
  className: '',
  dataTestId: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default FormRadioCheckBox;
