import { React, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@heroicons/react/outline';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { classNames } from '../../utils';

const RegularDropdown = ({
  items,
  onChange,
  selectedValue,
  placeholder,
  alignRight,
  isFull,
  customMargin,
  isForcedBottom = true,
  testId = 'selected-drop-down',
}) => {
  const selectedOption = items.find((i) => i.id === selectedValue);
  return (
    <Listbox
      className={`${customMargin || 'ml-0 md:ml-2 lg:ml-2'} relative filter-mobile`}
      value={selectedValue}
      onChange={(value) => { onChange(value); }}
    >
      <div className="mt-1 relative">
        <Listbox.Button className={`relative ${!isFull ? 'w-full wBtnDrop' : 'w-full'}  bg-white border border-gray-300
            rounded-md shadow-sm pl-3 pr-7 py-2 text-left cursor-default focus:outline-none
            focus:border-black-100 sm:text-sm`}
        >
          <span className="block truncate" data-testid={testId}>
            {selectedOption?.name || placeholder}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>

        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className={`absolute z-50 mt-1 sm:w-auto w-full bg-white shadow-lg max-h-60
          ${!alignRight ? 'right-0' : 'left-0'} ${!isForcedBottom ? '-top-24' : ''}
          rounded-md py-1 text-base ring-1 ring-black-100 overflow-auto focus:outline-none sm:text-sm`}
          >
            {items.map((i) => (
              <Listbox.Option
                className={({ active }) => classNames(
                  active ? 'text-gray-900 bg-black-100' : 'text-gray-900',
                  'cursor-default select-none relative py-2 pl-8 pr-6 w-full overflow-auto',
                )}
                key={i.id}
                value={i.id}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      data-testid={`${selected ? 'selected-option' : 'option'}`}
                      className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                    >
                      {i.name}
                    </span>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-gray-900' : 'text-green-regular',
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                        )}
                        data-testid="selected-option-checkbox"
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

RegularDropdown.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string,
  alignRight: PropTypes.bool,
  isFull: PropTypes.bool,
  isForcedBottom: PropTypes.bool,
  customMargin: PropTypes.string,
};

RegularDropdown.defaultProps = {
  items: [],
  onChange: () => {},
  selectedValue: 0,
  placeholder: 'Select option',
  alignRight: false,
  isFull: false,
  customMargin: '',
  isForcedBottom: true,
};

export default compose(
)(RegularDropdown);
